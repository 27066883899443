/**
 * Clickable blocks - entire block will be clickable
 * Styles are in _helpers.scss under .block--linked
 *
 * Usage:
 * {{#link}} data-clickable="{{link_path}}"{{/link}}
 * {{#url}} data-clickable="{{href}}"{{/url}}
 */

(function($) {
  Drupal.behaviors.clickable = {
    attach: function(context, settings) {
      $('[data-clickable!=""][data-clickable]', context).once('clickable').addClass('block--linked').on('click', function() {
        if (window.location.hash) {
          history.replaceState('', '', $(this).data('clickable')); // fix for hitchhiking hashes
        }
        window.location.href = $(this).data('clickable');
      });
    }
  };
})(jQuery);

/**
 * Generic expander functionality
 * case for basic expanding menu/ul
 *
 * Expanding Menu Usage / example markup:
 *
 *  <ul class="collapsible-menu">
 *    <li class="collapsible-menu__parent">
 *      <h3 class="collapsible-menu-trigger js-collapsible-menu-trigger">Parent Header</h3>
 *      <div class="collapsible-sub-menu">
 *        <ul class="">
 *          <li class=""><a class="" href="#">Link 1</a></li>
 *          <li class=""><a class="" href="#">Link 2</a></li>
 *          <li class=""><a class="" href="#">Link 3</a></li>
 *        </ul>
 *      </div>
 *    </li>
 *  </ul>
 *
 * static link variant inside menu
 *
 *  <ul class="collapsible-menu cs-menu">
 *    <li>
 *      <h3 class="collapsible-menu__static cs-menu__static"><a href="/customer-service-contact-us">Contact Us</a></h3>
 *    </li>
 *  </ul>
 *
 * Styles applied:
 * .collapsible-menu__parent - plus when closed, minus when open
 * .collapsible-sub-menu - closed by default
 * additional BEM classes can be added for styling
 *
 *
 * Expanding Mobile Block Usage / example markup:
 *  <section class="content-container collapsible-block">
 *    <a class="collapsible-block__anchor" name="cs-orders-section1" id="cs-orders-section4"></a>
 *
 *    <header class="collapsible-block__header clearfix">
 *      <h4 class="collapsible-block__title collapsible-block__title--pc mobile-hidden">Order Cancellations</h4>
 *      <div class="collapsible-block__title collapsible-block__title--mobile pc-hidden js-collapsible-block-trigger">
 *        <span>Order Cancellations</span>
 *        <i class="collapsible-block__icon"></i>
 *      </div>
 *    </header>
 *
 *    <div class="collapsible-block__content">
 *      <div class="clearfix"></div>
 *      <div class="collapsible-block__content-tout clearfix">
 *        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non gravida quam. Ut sed ultricies neque. Quisque pulvinar turpis vel magna iaculis vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec posuere interdum purus, quis porttitor dolor porta ac.
 *      </div>
 *
 *    </div>
 *  </section>
 */

(function($) {
  /**
   * Basic collapsible-menu behavior. Used in custom markup.
   */
  Drupal.behaviors.collapsibleMenu = {
    attach: function(context) {
      $('.js-collapsible-menu-trigger', context).on('click', function() {
        var $parentGrid = $(this).closest('.js-grid');
        $(this).closest('.collapsible-menu__parent').toggleClass('collapsible-menu__parent--expanded');
        // if we are inside a grid, recalculate heights on click
        if ($parentGrid.length) {
          var $items = $parentGrid.find('.js-grid-item');
          var colCountData = $parentGrid.data('grid-items-per-row');
          site.util.grids.equalHeightsRows($items, colCountData);
        }
      });
    }
  };

  /**
   * Basic collapsible-block behavior.
   */
  Drupal.behaviors.collapsibleBlock = {
    init: function(context) {
      context = context.currentTarget || context;

      var $block = $('.collapsible-block', context);
      var $trigger = $block.find('.js-collapsible-block-trigger');

      $block.each(function() {
        if ($(this).hasClass('collapsible-block--mobile-collapsed')) {
          $(this).addClass('collapsible-block--alt');
        }
        if ($(this).hasClass('collapsible-block--collapsed')) {
          $(this).addClass('collapsible-block--alt');
        }

        // default expansion
        $trigger.off('click').on('click', function() {
          $(this).closest('.collapsible-block').toggleClass('collapsible-block--alt');

          $(document).trigger('collapsibleBlock.trigger', {
            blocks: $block,
            trigger: $(this)
          });
        });
      });
    },
    attach: function(context) {
      $(document).on('collapsibleBlock.refresh', $.proxy(this.init, this));

      this.init(context);
    }
  };
})(jQuery);

(function($, generic) {
  // Route the old perlgem overlay method to colorbox:
  generic.overlay = {
    launch: function(args, event) {
      if (typeof event !== 'undefined') {
        event.preventDefault();
      }
      // ColorBox args sent along
      var cboxArgs = {
        'height': 'auto',
        'width': '768px',
        'margin': 'auto'
      };
      // Smoosh in any overrides from other calls, looks like args.cssStyle
      _.extend(cboxArgs, args);
      _.extend(cboxArgs, args.cssStyle); // get height/width overrides
      // When mobile, override any height/width and set to 100%
      if ($(window).width() <= 768) {
        _.extend(cboxArgs, {height: '100%', width: '100%'});
      }
      // Actual content of the overlay
      if (typeof args.content !== 'undefined') {
        cboxArgs.html = args.content;
      }
      // A custom class each launcher has the option of setting
      if (typeof args.cssClass !== 'undefined') {
        cboxArgs.className = args.cssClass;
      }
      // Scroll to an anchor, if sent over
      if (typeof args.inPageAnchor !== 'undefined') {
        cboxArgs.onComplete = function() {
          $('#cboxLoadedContent').scrollTo($('#' + args.inPageAnchor), 50);
        };
      }
      // Launch it
      $.colorbox(cboxArgs);
    },

    initLinks: function() {
      // Give us access to the parent scope so we can hit .launch()
      var self = this;
      // Links are tiggered via class, but indicate if already processed
      var $triggers = $('.overlay-link:not(.overlay-ready)').addClass('overlay-ready');

      // Depending on the type of link, the overlay needs to do something unique
      $triggers.each(function() {
        var args = {
              cssStyle: {}
            }, // args sent to overlay
            linkClassNames = $(this).attr('class'), // class name sent to colorbox
            linkHref = $(this).attr('href'), // actual href
            linkHrefWithEmbed = linkHref,
            inPageAnchor = $(this).data('inpage-anchor'), // see try/catch below
            overlayElement = $(this).data('overlay-content'); // use an existing element as content

        // used in overlay linking below
        var urlParts = document.createElement('a'); //
        urlParts.href = linkHref; //

        // Parse height options out of the link's class
        var widthRegexResults = linkClassNames.match(/overlay-width-(\d+)/);
        if (widthRegexResults) {
          args.cssStyle.width = widthRegexResults[1];
        }
        // Parse width options
        var heightRegexResults = linkClassNames.match(/overlay-height-(\d+)/);
        if (heightRegexResults) {
          args.cssStyle.height = heightRegexResults[1];
        }
        // Add a custom class, optionally
        var cssClassRegexResults = linkClassNames.match(/overlay-addclass-([a-z\-\_]+)/);
        if (cssClassRegexResults) {
          args.className = cssClassRegexResults[1];
        }

        // Make sure embed doesn't already exist. This gets added form internal
        // drupal embeddable urls
        if (typeof overlayElement !== 'undefined') {
          args.content = $(overlayElement).html();
        } else {
          try {
            if (!linkHref.match(/[\&\?]embed=1($|&)/)) {
              linkHrefWithEmbed = urlParts.pathname + (urlParts.search === '' ? '?' : urlParts.search + '&') + 'embed=1' + urlParts.hash;

              // Retain original link if it included the protocol.
              if (linkHref.match(/https?:\/\//)) {
                linkHrefWithEmbed = urlParts.protocol + '//' + urlParts.host + linkHrefWithEmbed;
              }
            }
          } catch (e) {
            linkHrefWithEmbed = linkHref;
          }

          // Fix the link within the page
          $(this).attr('href', linkHrefWithEmbed);
          // But this is actually used to launch overlay
          args.href = linkHrefWithEmbed;
        }

        // scrollTo behavior if we have a data attribute
        if (typeof inPageAnchor !== 'undefined') {
          args.inPageAnchor = inPageAnchor;
        }

        // Launch a colorbox overlay
        $(this).on('click', function(e) {
          // use our canonical launch function for all the goodies
          self.launch(args, e);
        });
      }); // .each()
    }, // initLinks

    hide: function() {
      $.colorbox.close();
    },

    getRBKeys: function() {
      generic.rb.language = generic.rb('language');
      generic.rb.language.rb_close = generic.rb.language.get('close');
    }
  };

  $(function() {
    generic.overlay.getRBKeys();
    generic.overlay.initLinks();
  });
})(jQuery, window.generic || {});

(function($) {
  'use strict';
  Drupal.behaviors.selectBox = {
    attach: function(context) {
      $('.selectBox:not(.selectBox-attached)', context).each(function() {
        $(this).addClass('selectBox-attached').selectBox({
          mobile: $(this).hasClass('selectBox--yes-even-for-mobile')
        });
      });
    },

    // Use this instead of direclty invoking $('.selectBox').selectBox('refresh').
    // The plugin doesn't take into account the fact that the selectBox may be
    // disabled for touch and will blow up if it is.
    refresh: function($selects) {
      $selects.each(function() {
        var control = $(this).data('selectBox-control');
        if (control && control.length) {
          $(this).selectBox('refresh');
        }
      });
    }
  };
})(jQuery);
window.site = window.site || {};

site.template = (function($, _, Mustache) {
  site.templates = site.templates || {};
  site.translations = site.translations || {};

  var defaults = {
    globals: {
      t: site.translations,
      variables: {
        // IE doesn't support location.origin, so...
        site_url: window.location.protocol + '//' + window.location.hostname
      }
    }
  };

  // include config settings from brand common module
  if (!_.isUndefined(Drupal) && !_.isUndefined(Drupal.settings) && !_.isUndefined(Drupal.settings.common)) {
    $.extend(defaults.globals.variables, Drupal.settings.common);
  }

  var public = {
    get: function(args) {
      var template = site.templates[args.name];

      // If that didn't work, search for a versioned match of the same template
      // (eg. template_v2)
      if (!template && args.name) {
        for (var key in site.templates) {
          if (site.templates.hasOwnProperty(key)) {
            var matcher = new RegExp(args.name + '_v(\\d+)$');
            if (matcher.test(key)) {
              template = site.templates[key];
              break;
            }
          }
        }
      }

      if (_.isUndefined(template)) {
        console.log('The template ' + args.name + ' cannot be found');
      }

      var rendered = this.render(template, args.data);

      if (_.isFunction(args.callback)) {
        var so = args.callback(rendered);
        if (!_.isUndefined(so)) {
          return so;
        }
      }

      return rendered;
    },

    render: function(template, data) {
      defaults.globals.t = site.translations;
      data = data || {};

      // You can pass just the template as a string if you want:
      if (_.isString(template)) {
        template = {
          content: template,
          data: {}
        };
      }

      var view = $.extend({}, defaults, template.data, data);
      var partials = {};

      if (!_.isUndefined(template.partials)) {
        $.each(template.partials, function(key, name) {
          if (_.isUndefined(site.templates[key]) && _.isUndefined(site.templates[name])) {
            console.log('The partial ' + key + ' or ' + name + ' cannot be found');
          }

          var pkey = !_.isUndefined(site.templates[key]) ? key : name;
          partials[pkey] = site.templates[pkey].content;
        });
      }

      return Mustache.render(template.content, view, partials);
    }
  };

  return public;
})(
  window.jQuery = window.jQuery || function() {},
  window._ = window._ || {},
  window.Mustache = window.Mustache || {}
);

// This file includes this commit to work with require.js:
// https://github.com/pouipouidesign/Unison/commit/30b1b0d9fcff831f7c4f92952911902f01284b57
// It also has some customizations to work with IE8.

/* global Unison: true */
Unison = (function() {
  'use strict';

  var win = window;
  var doc = document;
  var head = doc.head;
  var eventCache = {};
  var unisonReady = false;
  var currentBP;

  var util = {
    parseMQ : function(el) {
      var str = this.getStyleProperty(el, 'font-family');
      return str.replace(/"/g, '').replace(/'/g, '');
    },
    getStyleProperty: function(el, attr) {
      if (this.isUndefined(win.getComputedStyle)) {
        attr = attr.replace(/-(.)/g, function(match, group1) {
          return group1.toUpperCase();
        });
        return el.currentStyle[attr];
      } else {
        return win.getComputedStyle(el, null).getPropertyValue(attr);
      }
    },
    debounce : function(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        }, wait);
        if (immediate && !timeout) {
          func.apply(context, args);
        }
      };
    },
    isObject: function(e) { return typeof e === 'object'; },
    isUndefined: function(e) { return typeof e === 'undefined'; }
  };

  var events = {
    on: function(event, callback) {
      if (!util.isObject(eventCache[event])) {
        eventCache[event] = [];
      }
      eventCache[event].push(callback);
    },
    emit: function(event, data) {
      if (util.isObject(eventCache[event])) {
        var eventQ = eventCache[event].slice();
        for ( var i = 0; i < eventQ.length; i++ ) {
          eventQ[i].call(this, data);
        }
      }
    }
  };

  var breakpoints = {
    all: function() {
      var BPs = {};
      var allBP = util.parseMQ(doc.querySelector('title')).split(',');
      for ( var i = 0; i < allBP.length; i++ ) {
        var mq = allBP[i].trim().split(' ');
        BPs[mq[0]] = mq[1];
      }
      return ( unisonReady ) ? BPs : null ;
    },
    now: function(callback) {
      var nowBP = util.parseMQ(head).split(' ');
      var now = {
        name: nowBP[0],
        width: nowBP[1]
      };
      return (unisonReady) ? ((util.isUndefined(callback)) ? now : callback(now)) : null ;
    },
    update: function() {
      breakpoints.now(function(bp) {
        if ( bp.name !== currentBP ) {
          events.emit(bp.name);
          events.emit('change', bp);
          currentBP = bp.name;
        }
      });
    }
  };

  if (util.isUndefined(head)) {
    head = document.getElementsByTagName('head')[0];
  }

  win.onresize = util.debounce(breakpoints.update, 100);

  // if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
    unisonReady = util.getStyleProperty(head, 'clear') !== 'none';
    breakpoints.update();
  // } else {
  //   doc.addEventListener('DOMContentLoaded', function(){
  //     unisonReady = util.getStyleProperty(head, 'clear') !== 'none';
  //     breakpoints.update();
  //   });
  // }

  return {
    fetch: {
      all: breakpoints.all,
      now: breakpoints.now
    },
    on: events.on,
    emit: events.emit,
    util: {
      debounce: util.debounce,
      isObject: util.isObject
    }
  };
})();

(function($, site) {

  site.videos = {
    isMobile: {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function() {
        return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
      }
    },

    open: function(opts) {
      var provider = !_.isEmpty(opts.provider) ? opts.provider : '';

      if (provider == 'zentrick') {
        this.openZentrick(opts);
      }
      else if (provider == 'youtube') {
        this.openYouTube(opts);
      }
      else if (provider == 'html5') {
        this.openHTML5(opts);
      }
      else {
        console.log('Video provider not yet supported.');
      }
    },

    openZentrick: function(opts) {
      var publicationId = opts.zentrickId;
      var context = !_.isEmpty(opts.context) ? opts.context : document;
      var scroll = $(window).scrollTop();
      if (!!site.isZentrickPlayerAPIReady && !_.isEmpty(publicationId)) {
        var content = '<div class="js-content-block-video-placeholder zentrick-video"></div>';
        var options = {
          content: content,
          className: 'colorbox--dark',
          // height: 'auto',
          // width: '100%',
          maxWidth: '100%',
          maxHeight: '100%',
          transition: 'none',
          fixed: 'true',
          appearDuration: 1.0,
          opacity: 0,
          overflow: 'hidden',
          cssStyle: {
            border: 'none',
            padding: 0,
            backgroundColor: '#000',
            height: '100%',
            width: '100%',
            position: 'fixed',
            className: opts.className,
          },
          onComplete: function() {
            var _this = this;
            _this.$cbox = $('#colorbox.overlay--content-block-video', context);

            setTimeout(function() {
              // Hide overflow on the body
              $('html,body').addClass('no-scroll');
            }, 300);

            // Animate in
            _this.$cbox.addClass('active');

            // On any resize, recalculate
            $(window).on('resize.videoOverlay', _.debounce(function() {
              $('html,body').addClass('no-scroll');
              $.colorbox.resize({
                width: '100%',
                height: '100%'
              });
            }, 400));
            $.colorbox.resize({
              width: '100%',
              height: '100%'
            });
          },
          onCleanup: function() {
            var _this = this;
            _this.$cbox.removeClass('active');
            $('html,body').removeClass('no-scroll');
            $(window).off('resize.videoOverlay');
            $('html').scrollTop(scroll);
          }
        };

        // Remove existing videos
        $('.zentrick-video').remove();

        // Create a new video container element
        var id = 'p' + +new Date();
        var $videoContainer = $('<div></div>').attr({
          'class': 'hidden zentrick-video',
          'id':    id
        });
        $videoContainer
          .append( $('<div />').attr('class', 'embed-container').append( $('<div />').attr('id', id + '-inner') ) )
          .appendTo('body');

        // Initialize the player
        site.zentrickPlayer = new zentrick.Player(
          id + '-inner',
          publicationId,
          { html5: 1 }
        );

        // Add our new video player to an overlaya
        options.content = '<div class="js-content-block-video-placeholder youtube-video">' + $('#' + id).html() + '</div>';
        options.fastIframe = false;

        if (opts.openOverlay) {
          generic.overlay.launch(options);
        } else {
          opts.$content.html(options.content);
          opts.$content.trigger('video.loaded');
        }
      }
      else {
        console.log('Zentrick not ready or missing publication id.');
      }
    },

    openYouTube: function(opts) {
      var ytId = opts.youTubeId;
      var context = !_.isEmpty(opts.context) ? opts.context : document;
      var content = '<div class="js-content-block-video-placeholder youtube-video"></div>';
      var scroll = $(window).scrollTop();
      function createVideoPlayerDiv($elem, i) {
        var id = $elem.attr('class') + '-' + i;
        $elem
          .parent()
          .append('<div />')
          .children('div')
          .attr('id', id);
        return id;
      }

      function addVideo(ytId, iframeId) {
        /*global YT */
        new YT.Player(iframeId, {
          height: '100%',
          width: '100%',
          videoId: ytId,
          playerVars: {
            modestbranding: 0,
            controls: 1,
            showinfo: 0,
            rel: 0,
          },
          events: {
            'onReady': function(event) {
              // Don't autoplay on any mobile device
              if (!site.videos.isMobile.any()) {
                event.target.playVideo();
              }
            },
            'onStateChange': function() {
              // stuff
            }
          }
        });
      }

      var options = {
        content: content,
        transition: 'none',
        fixed: 'true',
        appearDuration: 1.0,
        opacity: 0,
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        cssStyle: {
          border: 'none',
          padding: 0,
          backgroundColor: '#000',
          height: '100%',
          width: '100%',
          position: 'fixed',
          className: opts.className,
          onComplete: function() {
            var _this = this;
            _this.$cbox = $('#colorbox.overlay--content-block-video', context);

            // Create a new div for the player to take over inside of the colorbox
            var iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder', _this.$cbox), Math.floor((+new Date()) / 1000));

            // Remove our empty container
            $('.js-content-block-video-placeholder', _this.$cbox).remove();

            // Instantiate the video
            addVideo(ytId, iframeId);

            // Animate in
            _this.$cbox.addClass('active');

            setTimeout(function() {
              // Hide overflow on the body
              $('html,body').addClass('no-scroll');
            }, 300);

            // On any resize, recalculate
            $(window).on('resize.videoOverlay', _.debounce(function() {
              $('html,body').addClass('no-scroll');
              $.colorbox.resize({
                width: '100%',
                height: '100%'
              });
            }, 400));
            $.colorbox.resize({
              width: '100%',
              height: '100%'
            });
          },
          onCleanup: function() {
            var _this = this;
            _this.$cbox.removeClass('active');
            $('html,body').removeClass('no-scroll');
            $(window).off('resize.videoOverlay');
            $('html').scrollTop(scroll);
          }
        }
      };

      if (opts.openOverlay) {
        generic.overlay.launch(options);
      } else {
        opts.$content.html(content);
        var iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder', opts.$content), Math.floor((+new Date()) / 1000));
        addVideo(ytId, iframeId);
        opts.$content.trigger('video.loaded');
      }
    },

    openHTML5: function(opts) {
      var content = opts.content;
      var context = !_.isEmpty(opts.context) ? opts.context : document;
      var scroll = $(window).scrollTop();
      var options = {
        content: content,
        transition: 'none',
        fixed: 'true',
        appearDuration: 1.0,
        opacity: 0,
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        cssStyle: {
          border: 'none',
          padding: 0,
          backgroundColor: '#000',
          height: '100%',
          width: '100%',
          position: 'fixed',
          className: opts.className,
          onComplete: function() {
            var _this = this;
            _this.$cbox = $('#colorbox.overlay--content-block-video', context);

            $('video', _this.$cbox).get(0).play();

            // Animate in
            _this.$cbox.addClass('active');

            setTimeout(function() {
              // Hide overflow on the body
              $('html,body').addClass('no-scroll');
            }, 300);

            // On any resize, recalculate
            $(window).on('resize.videoOverlay', _.debounce(function() {
              $('html,body').addClass('no-scroll');
              $.colorbox.resize({
                width: '100%',
                height: '100%'
              });
            }, 400));
            $.colorbox.resize({
              width: '100%',
              height: '100%'
            });
          },
          onCleanup: function() {
            var _this = this;
            _this.$cbox.removeClass('active');
            $('html,body').removeClass('no-scroll');
            $(window).off('resize.videoOverlay');
            $('html').scrollTop(scroll);
          }
        }
      };
      if (opts.openOverlay) {
        generic.overlay.launch(options);
      } else {
        opts.$content.html(opts.content);
        opts.$content.find('video').length ? opts.$content.find('video').get(0).play() : false;
        opts.$content.trigger('video.loaded');
      }
    }
  };

})(jQuery, site);
